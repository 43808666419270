import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import "./Empower.css"


function Empower() {
    let match = useRouteMatch();
    const data = {
        "iE": {
            "name": 'iEnterprise',
            "date": [
                '27-10-2023',
                '28-09-2023',
                '28-08-2023',
                '27-07-2023',
                '30-06-2023',
                '31-05-2023',
                '28-04-2023',
                '29-03-2023',
                '28-02-2023',
                '30-01-2023',
                '19-12-2022',
                '4-11-2022',
                '7-10-2022',
                '14-9-2022',
                '4-8-2022',
                '7-7-2022',
                '13-6-2022',
                '6-5-2022',
                '11-4-2022',
                '2-3-2022',
                '15-2-2022',
                '10-1-2022',
            ],
        },
        "crmp": {
            "name": 'Civil Resources Matching Platform',
            "date": [
                '27-10-2023',
                '28-09-2023',
                '28-08-2023',
                '27-07-2023',
                '30-06-2023',
                '31-05-2023',
                '28-04-2023',
                '29-03-2023',
                '28-02-2023',              
                '30-01-2023',
                '19-12-2022',
                '4-11-2022',
                '7-10-2022',
                '14-9-2022',
                '4-8-2022',
                '7-7-2022',
                '13-6-2022',
                '6-5-2022',
                '11-4-2022',
                '2-3-2022',
                '15-2-2022',
                '10-1-2022',
            ],
        },
        "cwk": {
            "name": 'CWK Global Group',
            "date": [
                '1-11-2021',
            ],
        },
        "cusport": {
            "name": 'The Jockey Club "Sports without Limits" Youth Empowerment Programme',
            "date": [
                '27-10-2023',
                '28-09-2023',
                '28-08-2023',
                '27-07-2023',
                '30-06-2023',
                '31-05-2023',
                '28-04-2023',
                '29-03-2023',
                '28-02-2023',
                '30-01-2023',
                '19-12-2022',
                '4-11-2022',
                '7-10-2022',
                '14-9-2022',
                '4-8-2022',
                '7-7-2022',
                '13-6-2022',
                '6-5-2022',
                '11-4-2022',
                '2-3-2022',
                '28-1-2022',
            ],
        },
        "wingsfa": {
            "name": 'Wings Flight Academy',
            "date": [
                '11-1-2022',
                '4-5-2023',
                '8-8-2023',
            ],
        },
        "aam": {
            "name": 'Asia Asset Management',
            "date": [
                '6-4-2022',
                '15-2-2022',
            ],
        },
        "yj": {
            "name": 'York Joint',
            "date": [
                '27-10-2023',
                '28-09-2023',
                '28-08-2023',
                '27-07-2023',
                '30-06-2023',
                '31-05-2023',
                '28-04-2023',
                '29-03-2023',
                '28-02-2023',
                '30-01-2023',
                '19-12-2022',
                '4-11-2022',
                '7-10-2022',
                '14-9-2022',
                '4-8-2022',
                '7-7-2022',
                '13-6-2022',
                '6-5-2022',
                '1-4-2022',
            ],
        },
        "asf": {
            "name": 'Ajmal Samuel Foundation',
            "date": [
                '24-5-2022',
            ],
        },
        "cocreer": {
            "name": 'CO CREER',
            "date": [
                '24-5-2022',
            ],
        },
        "sunrise_ent": {
            "name": 'Sunrise Enterprises Co',
            "date": [
                '24-5-2022',
            ],
        },
        "tf": {
            "name": 'Teen Future',
            "date": [
                '1-4-2022',
            ],
        },
        "ta": {
            "name": 'Tecky Academy',
            "date": [
                '18-5-2022',
            ],
        },
        "hkbu": {
            "name": '賽馬會「敢試．敢聘．敢做」計劃',
            "date": [
                '7-11-2022',
            ],
        },
        "hlt": {
            "name": 'HLT Logistics',
            "date": [
                '28-04-2023',
            ],
        },
        "ob": {
            "name": 'OriginBit',
            "date": [
                '01-01-2024',
            ],
        },
    };

    return (
        <div>
            <Switch>
                <Route path={`${match.path}/iEnterprise`}>
                    <Conformance name={data.iE.name} date={data.iE.date} />
                    {/* <Conformance name={data.iEnterprise.name} allDate={data.iEnterprise.allDate} /> */}
                </Route>
                <Route path={`${match.path}/crmp`}>
                    <Conformance name={data.crmp.name} date={data.crmp.date} />
                </Route>
                <Route path={`${match.path}/cwk`}>
                    <Conformance name={data.cwk.name} date={data.cwk.date} />
                </Route>
                <Route path={`${match.path}/cusport`}>
                    <Conformance name={data.cusport.name} date={data.cusport.date} />
                </Route>
                <Route path={`${match.path}/wingsfa`}>
                    <Conformance name={data.wingsfa.name} date={data.wingsfa.date} />
                </Route>
                <Route path={`${match.path}/aam`}>
                    <Conformance name={data.aam.name} date={data.aam.date} />
                </Route>
                <Route path={`${match.path}/yorkjoint`}>
                    <Conformance name={data.yj.name} date={data.yj.date} />
                </Route>
                <Route path={`${match.path}/asf`}>
                    <Conformance name={data.asf.name} date={data.asf.date} />
                </Route>
                <Route path={`${match.path}/cocreer`}>
                    <Conformance name={data.cocreer.name} date={data.cocreer.date} />
                </Route>
                <Route path={`${match.path}/sunrise-ent`}>
                    <Conformance name={data.sunrise_ent.name} date={data.sunrise_ent.date} />
                </Route>
                <Route path={`${match.path}/tf`}>
                    <Conformance name={data.tf.name} date={data.tf.date} />
                </Route>
                <Route path={`${match.path}/ta`}>
                    <Conformance name={data.ta.name} date={data.ta.date} />
                </Route>
                <Route path={`${match.path}/hkbu`}>
                    <Conformance name={data.hkbu.name} date={data.hkbu.date} />
                </Route>
                <Route path={`${match.path}/hlt`}>
                    <Conformance name={data.hlt.name} date={data.hlt.date} />
                </Route>
                <Route path={`${match.path}/ob`}>
                    <Conformance name={data.ob.name} date={data.ob.date} />
                </Route>
            </Switch>
        </div>
    );
}

// function Conformance2(site) {
//     // let { topicId } = useParams();
//     return (
//         <div className="container">
//             <div className="row">
//                 <h1>Record of Web Content Accessibility Guidelines (WCAG) Conformance Update</h1>
//                 <p><span >iEnterprise Accessibility Center</span> hereby certify that <span>{site.name}</span> website conforms to the World Wide Web Consortium (W3C) Web Content Accessibility Guidelines (WCAG) 2.1 Level AA requirements to the maximum extent possible.</p>
//             </div>
//             <div className="row">
//                 <div className="col text-center">
//                     <img className="wcag" src="/img/w3c21AA.png" alt="Web Content Accessibility Guidelines (WCAG) 2.1 at Level AA" />
//                 </div>
//                 <div className="col text-center">
//                     <img className="wars" src="/img/hk_web_accessibility_logo.png" alt="hk web accessibility logo" />
//                 </div>
//             </div>
//             <div className="row justify-content-center">
//                 <div className="col text-center">
//                     <p className="conformance">WCAG Conformance Update : {site.date}</p>
//                 </div>
//             </div>
//         </div>
//     );
// }


function Conformance(site) {
    const DateLoop = () => {
        var rows = [];
        // for (var i = site.date.length-1; i >= 0; i--) {
        for (var i = 0; i < site.date.length; i++) {
            rows.push(
                site.date[i], <br />
            );
        }
        return rows
    }

    return (
        <div className="container">
            <div className="row">
                <h1>Record of Web Content Accessibility Guidelines (WCAG) Conformance Update</h1>
                <p><span >iEnterprise Accessibility Center</span> hereby certify that <span>{site.name}</span> website conforms to the World Wide Web Consortium (W3C) Web Content Accessibility Guidelines (WCAG) 2.1 Level AA requirements to the maximum extent possible.</p>
            </div>
            <div className="row">
                <div className="col text-center">
                    <img className="wcag" src="/img/w3c21AA.png" alt="Web Content Accessibility Guidelines (WCAG) 2.1 at Level AA" />
                </div>
                <div className="col text-center">
                    <img className="wars" src="/img/hk_web_accessibility_logo.png" alt="hk web accessibility logo" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col text-center">
                    <p className="conformance">WCAG Conformance Update : </p>
                    <p className="conformance"><DateLoop /></p>
                </div>
            </div>
        </div>
    );
}

export default Empower;
